@import 'scss/variables.scss';

.weekDay {
  width: calc(100% / 7 - value($gap, small) * 6 / 7);
}

.day {
  width: calc(100% / 7 - value($gap, small) * 6 / 7);
  height: 40px;
  border-radius: value($radius, normal);
  border: solid transparent 1px;
  opacity: .3;

  &.current {
    border-color: var(--theme-primary-color, $primaryColor);
  }

  &.active {
    border-color: var(--theme-primary-color, $primaryColor);
    opacity: 1;
    cursor: pointer;
  }

  &.selected {
    border-color: var(--theme-primary-color, $primaryColor);
    background-color: var(--theme-primary-color, $primaryColor);
    opacity: 1;
    cursor: pointer;
  }
}

.arrow {
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
    opacity: .3;
  }
}