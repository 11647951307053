@import './variables.scss';

.wFull {
  width: 100%;
}

.wHalf {
  width: 50%;
}

.wAuto {
  width: auto;
}

.hFull {
  height: 100%;
}

.hHalf {
  height: 50%;
}

.hAuto {
  height: auto;
}

@for $i from 0 through 20 {
  .g#{$i} {
    gap: $step * $i;
  }
}

@for $i from 0 through 20 {
  .f#{$i} {
    flex: $i;
  }
}

@for $i from 0 through 40 {
  .p#{$i} {
    padding: $step * $i;
  }

  .px#{$i} {
    padding-inline-start: $step * $i;
    padding-inline-end: $step * $i;
  }

  .py#{$i} {
    padding-top: $step * $i;
    padding-bottom: $step * $i;
  }

  .pt#{$i} {
    padding-top: $step * $i;
  }

  .pb#{$i} {
    padding-bottom: $step * $i;
  }

  .pl#{$i} {
    padding-inline-start: $step * $i;
  }

  .pr#{$i} {
    padding-inline-end: $step * $i;
  }
}