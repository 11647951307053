@import 'scss/variables.scss';

.image {
  width: 84px;
  min-width: 84px;
  height: 84px;
  overflow: hidden;
  border-radius: value($radius, small);
  background-color: var(--theme-bg-color, $primaryBgColor);

  &.logo {
    img {
      opacity: .3;
    }
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.checkbox {
  width: 24px;
  min-width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid var(--theme-disabled-color, $primaryDisabledColor);
  transition: all .2s;

  &.active {
    border-color: var(--theme-primary-color, $primaryColor);
    background-color: var(--theme-primary-color, $primaryColor);
  }
}