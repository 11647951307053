@import 'scss/variables.scss';

.button {
  background-color: transparent;
  border: solid transparent 1px;
  border-radius: value($radius, normal);
  height: $step * 12;
  padding: 0 value($padding, large);
  font-family: value($font, family);
  font-weight: value($font, weight, black);
  font-size: value($font, size, small);
  line-height: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: value($gap, small);
  position: relative;
  z-index: 0;
  white-space: nowrap;
  transition: all .2s;
  text-decoration: none;
  cursor: pointer;

  &.loading {
    color: transparent;
  }

  span {
    position: absolute;
    z-index: 0;
    inset: 0;
  }
}

.primary {
  color: value($button, primary, text);
  background-color: var(--theme-primary-color, value($button, primary, color));
  border-color: var(--theme-primary-color, value($button, primary, color));

  svg {
    fill: value($button, primary, text);
  }

  &:not(.disabled):hover {
    background-color: var(--theme-hover-color, value($button, primary, hover));
    border-color: var(--theme-hover-color, value($button, primary, hover));
  }

  &:not(.disabled):active {
    background-color: var(--theme-active-color, value($button, primary, active));
    border-color: var(--theme-active-color, value($button, primary, active));
  }

  &.disabled {
    background-color: var(--theme-disabled-color, value($button, primary, disabled));
    border-color: var(--theme-disabled-color, value($button, primary, disabled));
    cursor: not-allowed;
  }

  &.outlined {
    color: var(--theme-primary-color, value($button, primaryOutlined, color));
    background-color: transparent;
    border-color: var(--theme-primary-color, value($button, primaryOutlined, color));

    svg {
      fill: var(--theme-primary-color, value($button, primaryOutlined, color));
    }

    &:not(.disabled):hover {
      color: var(--theme-hover-color, value($button, primaryOutlined, hover));
      background-color: transparent;
      border-color: var(--theme-hover-color, value($button, primaryOutlined, hover));

      svg {
        fill: var(--theme-hover-color, value($button, primaryOutlined, hover));
      }
    }

    &:not(.disabled):active {
      color: var(--theme-active-color, value($button, primaryOutlined, active));
      background-color: transparent;
      border-color: var(--theme-active-color, value($button, primaryOutlined, active));

      svg {
        fill: var(--theme-active-color, value($button, primaryOutlined, active));
      }
    }
  
    &.disabled {
      color: var(--theme-disabled-color, value($button, primaryOutlined, disabled));
      background-color: transparent;
      border-color: var(--theme-disabled-color, value($button, primaryOutlined, disabled));
      cursor: not-allowed;

      svg {
        fill: var(--theme-disabled-color, value($button, primaryOutlined, disabled));
      }
    }
  }
}

.danger {
  color: value($button, danger, text);
  background-color: value($button, danger, color);
  border-color: value($button, danger, color);

  svg {
    fill: value($button, danger, text);
  }

  &:not(.disabled):hover {
    background-color: value($button, danger, hover);
    border-color: value($button, danger, hover);
  }

  &:not(.disabled):active {
    background-color: value($button, danger, active);
    border-color: value($button, danger, active);
  }

  &.disabled {
    background-color: value($button, danger, disabled);
    border-color: value($button, danger, disabled);
    cursor: not-allowed;
  }

  &.outlined {
    color: value($button, dangerOutlined, color);
    background-color: transparent;
    border-color: value($button, dangerOutlined, color);

    svg {
      fill: value($button, dangerOutlined, color);
    }

    &:not(.disabled):hover {
      color: value($button, dangerOutlined, hover);
      background-color: transparent;
      border-color: value($button, dangerOutlined, hover);

      svg {
        fill: value($button, dangerOutlined, hover);
      }
    }

    &:not(.disabled):active {
      color: value($button, dangerOutlined, active);
      background-color: transparent;
      border-color: value($button, dangerOutlined, active);

      svg {
        fill: value($button, dangerOutlined, active);
      }
    }
  
    &.disabled {
      color: value($button, dangerOutlined, disabled);
      background-color: transparent;
      border-color: value($button,  dangerOutlined, disabled);
      cursor: not-allowed;

      svg {
        fill: value($button, dangerOutlined, disabled);
      }
    }
  }
}

.large {
  font-size: value($font, size, normal);
  height: $step * 14;
}

.small {
  font-size: value($font, size, xs);
  height: $step * 8;
  border-radius: value($radius, small);
  padding: 0 value($padding, normal);
}