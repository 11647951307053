@import 'scss/variables.scss';

.title {
  cursor: pointer;

  svg {
    transition: all .2s;
  }
}

.details {
  max-height: 82px;
  overflow: hidden;
  transition: all .2s;

  &.hide {
    max-height: 0;
  }
}

.expand {
  max-height: 0;
  overflow: hidden;
  transition: all .2s;

  &.show {
    max-height: 250px;
  }
}

.line {
  border-top: solid transparent 1px;

  &.border {
    border-color: var(--theme-bg-color, $primaryBgColor)
  }
}

.add {
  position: relative;
  z-index: 0;
  width: 20px;
  height: 20px;
  cursor: pointer;

  &:before {
    content: '+';
    line-height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: $whiteColor;
    color: var(--theme-primary-color, $primaryColor);
    position: absolute;
    font-size: 16px;
    z-index: 1;
    bottom: 1px;
    inset-inline-end: 1px;
  }
}