@import 'scss/variables.scss';

.container {
  .logo {
    width: 88px;
    min-width: 88px;
    height: 88px;
    border-radius: 12px;
    background: $primaryDisabledColor;
    border: solid 4px $whiteColor;
    box-shadow: 0px 4px 18.5px 0px var(--theme-shadow-color, rgba(184, 184, 184, 0.39));
    overflow: hidden;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .about {
    position: relative;
    z-index: 0;
    padding: value($padding, normal);
    border-radius: value($radius, small);
    // background: var(--theme-light-color, $primaryLightColor);
    box-shadow: 0px 2px 12px 0px var(--theme-shadow-color, rgba(39, 90, 123, 0.17));
  
    .svg {
      position: absolute;
      z-index: 0;
  
      &.leftTop {
        left: 0;
        top: 0;
      }
  
      &.leftBottom {
        left: 0;
        bottom: 0;
      }
  
      &.rightTop {
        right: 0;
        top: 0;
      }
  
      &.rightBottom {
        right: 0;
        bottom: 0;
      }
    }
  
    .inner {
      position: relative;
      z-index: 1;
    }
  }
}