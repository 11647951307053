.flex {
  display: flex;
  text-decoration: none;
}

.row {
  flex-direction: row;
}

.column {
  flex-direction: column;
}

.jCenter {
  justify-content: center;
}

.jStart {
  justify-content: flex-start;
}

.jEnd {
  justify-content: flex-end;
}

.jBetween {
  justify-content: space-between;
}

.jAround {
  justify-content: space-around;
}

.jNormal {
  justify-content: normal;
}

.aCenter {
  align-items: center;
}

.aStart {
  align-items: flex-start;
}

.aEnd {
  align-items: flex-end;
}

.aStretch {
  align-items: stretch;
}

.aNormal {
  align-items: normal;
}

.wrap {
  flex-wrap: wrap;
}

.nowrap {
  flex-wrap: nowrap;
}

.scroll {
  overflow: auto;
  position: relative;
  z-index: 0;

  .absolute {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
  }
}