@import 'scss/variables.scss';

.layout {
  position: absolute;
  background-color: rgba(0, 0, 0, .5);
  opacity: 0;
  z-index: 25;
  inset: 0;
  display: flex;
  flex-direction: column;
  backdrop-filter: blur(6px);

  &.noAnimation {
    opacity: 1;
  }

  @media (max-width: 768px) {
    position: fixed;
  }

  .content {
    width: 100%;
    margin: 0;
    position: relative;
    z-index: 0;
    flex: 1;

    .inner {
      width: 100%;
      position: absolute;
      z-index: 0;
      left: 0;
      right: 0;
      bottom: 0;
      max-height: 100%;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 0;
        display: none;
      }

      & > div {
        width: 100%;

        & > span {
          display: block;
          height: 120px;
          width: 100%;
        }

        .dialog {
          border-radius: value($radius, xl) value($radius, xl) 0 0;
          background-color: $whiteColor;
          width: 100%;
          overflow: hidden;
        }
      }
    }
  }

  .actions {
    background: $whiteColor;
    position: realative;
    z-index: 2;
    box-shadow: 0 0 20px rgba(184, 184, 184, 0.5);

    &.bottom {
      box-shadow: none;
    }
  }

  .backWhite {
    background-color: $whiteColor;
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0;
    right: 0;
    height: 400px;
  }
}