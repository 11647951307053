@import 'scss/variables.scss';

.item {
  width: 25%;

  &.item20 {
    width: 20%;
  }

  &.item17 {
    width: 17%;

    .icon {
      width: 48px;
      height: 48px;
    }
  }

  &.item15 {
    width: 15%;

    .icon {
      width: 40px;
      height: 40px;
    }
  }

  .icon {
    width: 56px;
    height: 56px;
    border-radius: value($radius, normal);
    border: 1px solid #D4DEE5;
    cursor: pointer;
  }
}