@import 'scss/variables.scss';

.container {
  padding: value($padding, xl) value($padding, large) value($padding, large);

  .icon {
    width: 56px;
    height: 56px;
    border-radius: value($radius, normal);
    border: solid $dangerColor 1px;
    background-color: $dangerBgColor;
  }
}