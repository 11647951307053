@import 'scss/variables.scss';

.calendar {
  display: flex;
  flex-direction: column;
  gap: value($gap, normal);
  overflow: hidden;
  max-height: 0;
  transition: all .2s;

  &.expand {
    max-height: 360px;
  }
}

.wlist {
  overflow: hidden;
  max-height: 0;
  transition: all .2s;

  &.expand {
    max-height: 360px;
  }
}