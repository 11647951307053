@import 'scss/variables.scss';

.container {
  padding: value($padding, xl) value($padding, large) value($padding, large);

  .icon {
    width: 56px;
    height: 56px;
    border-radius: value($radius, normal);
    border: solid var(--theme-primary-color, $primaryColor) 1px;
    background-color: var(--theme-bg-color, $primaryBgColor);
  }

  .details {
    padding: value($padding, normal);
    border-radius: value($radius, normal);
    border: solid var(--theme-bg-color, $primaryBgColor) 1px;
  }
}

.iframeContainer {
  .backBox {
    padding: value($padding, xl) value($padding, large);
    position: relative;
    z-index: 0;

    .svg {
      position: absolute;
      z-index: -1;
      top: -80px;
      left: 0;
      right: 0;
    }

    .back {
    cursor: pointer;
    }
  }

  .title {
    padding: 0 value($padding, large) value($padding, large);
  }

  .iframeBox {
    width: 100%;
    height: 500px;
    position: relative;
    z-index: 0;

    .loader {
      position: absolute;
      z-index: 0;
      inset: 0;
    }

    .iframe {
      border: 0;
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 1;
    }
  }
}