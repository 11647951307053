@import 'scss/variables.scss';

.icon {
  transition: transform .2s;
}

.primary {
  fill: var(--theme-primary-color, value($font, color, highlight));
}

.primaryColor {
  color: var(--theme-primary-color, value($font, color, highlight));
}

.muted {
  fill: value($font, color, muted);
}

.gray {
  fill: value($font, color, gray);
}

.danger {
  fill: $dangerColor;
}

.white {
  fill: $whiteColor;
}

.rotate {
  transform: rotate(180deg);
}

.normal {
  width: value($font, size, large);
  height: value($font, size, large);
}

.large {
  width: value($font, size, xl);
  height: value($font, size, xl);
}

.small {
  width: value($font, size, normal);
  height: value($font, size, normal);
}

.xs {
  width: value($font, size, xs);
  height: value($font, size, xs);
}

.xl {
  width: value($font, size, xl);
  height: value($font, size, xl);
}

@for $i from 0 through 64 {
  .s#{$i} {
    width: #{$i}px;
    height: #{$i}px;
  }
}