@import 'scss/variables.scss';

.title {
  cursor: pointer;

  svg {
    transition: all .2s;
  }
}

.details {
  max-height: 82px;
  overflow: hidden;
  transition: all .2s;

  &.hide {
    max-height: 0;
  }
}

.expand {
  max-height: 0;
  overflow: hidden;
  transition: all .2s;

  &.show {
    max-height: 250px;
  }
}

.line {
  border-top: solid transparent 1px;

  &.border {
    border-color: var(--theme-bg-color, $primaryBgColor)
  }
}