@import 'scss/variables.scss';

.container {
  padding: value($padding, normal);
  height: 100%;
  background-color: rgba(39, 90, 123, 0.04);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 0;

  @media (max-width: $mobileBreakpoint) {
    padding: 0;
    height: 100%;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    inset: 0;
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, .7);
  }

  .card {
    background-color: #fff;
    border-radius: value($radius, normal);
    width: 375px;
    height: 90%;
    overflow: hidden;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .2);
    position: relative;
    z-index: 0;

    @media (max-width: $mobileBreakpoint) {
      width: 100%;
      min-height: 100%;
      box-shadow: none;
      border-radius: 0;
    }
  }
}