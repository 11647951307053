@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

html {
  height: 100%;
}

body {
  height: 100%;
  width: 100%;
  margin: 0;
  background-color: #fff;
}

#root {
  height: 100%;
  width: 100%;
}

* {
  box-sizing: border-box;
}