@import 'scss/variables.scss';

.container {
  height: $heroHeight;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;

  @media (max-width: $mobileBreakpoint) {
    position: fixed;
  }

  &.hidden {
    opacity: 0.5;
  }

  .welcome {
    position: absolute;
    z-index: 1;
    padding: 24px;
    top: 0;
    left: 0;
    right: 0;
    pointer-events: none;
  }

  .slider {
    position: relative;
    z-index: 0;
    height: $heroHeight;

    .dots {
      position: absolute;
      z-index: 10;
      bottom: 52px;
      left: 0;
      right: 0;

      .dot {
        width: 12px;
        height: 4px;
        border-radius: 35px;
        background: rgba(255, 255, 255, 0.50);

        &.active {
          width: 16px;
          background: #fff;
        }
      }
    }
  }

  .slide {
    width: 100%;
    height: $heroHeight;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}