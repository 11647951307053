@import 'scss/variables.scss';

.wrapper {
  border-radius: value($radius, normal);
  border-color: value($input, border);
  border-width: 1px;
  border-style: solid;
  transition: all .2s;
  position: relative;

  svg {
    position: absolute;
    z-index: 0;
    inset-inline-end: 16px;
  }

  &.active {
    border-color: var(--theme-primary-color, value($input, border));
  }

  &.disabled {
    border-color: value($input, disabled);
  }

  &.error {
    border-color: $dangerColor;
  }
}

.select {
  font-family: value($font, family);
  font-size: value($font, size, normal);
  font-weight: value($font, weight, normal);
  color: value($input, color);
  padding: value($padding, normal);
  padding-inline-end:  value($padding, xl);
  background-color: transparent;
  border: 0;
  width: 100%;
  height: 100%;
  outline: none;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &.placeholder {
    color: value($input, placeholder);
  }

  &.center {
    text-align: center;
  }

  &.left {
    text-align: start;
  }

  &.right {
    text-align: end;
  }
}