@import 'scss/variables.scss';

.title {
  cursor: pointer;
  padding: value($padding, normal);

  svg {
    transition: all .2s;
  }
}

.details {
  max-height: 400px;
  overflow: hidden;
  transition: all .2s;

  &.hide {
    max-height: 0;
  }
}