@import 'scss/variables.scss';

.container {
  padding: value($padding, xl) value($padding, large);
  position: relative;
  z-index: 0;

  .svg {
    position: absolute;
    z-index: -1;
    top: -80px;
    left: 0;
    right: 0;
  }

  .back {
    position: absolute;
    z-index: 0;
    inset-inline-start: value($padding, large);
    top: calc(value($padding, xl) + 4px);
    cursor: pointer;
  }

  .steps {
    position: relative;
    z-index: 0;

    .circle {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      border: 1px solid #E2E8F0;
      background-color: $whiteColor;
      position: relative;
      z-index: 1;

      &.current {
        border-color: var(--theme-primary-color, $primaryColor);
      }

      &.past {
        border-color: var(--theme-primary-color, $primaryColor);
        background-color: var(--theme-primary-color, $primaryColor);
      }
    }

    .line {
      position: absolute;
      z-index: 0;
      height: 2px;
      top: 50%;
      margin-top: -1px;
      left: 2px;
      right: 2px;
      background-color: #E2E8F0;

      &.active {
        background-color: var(--theme-primary-color, $primaryColor);
      }
    }
  }
}