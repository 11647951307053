@import 'scss/variables.scss';

.card {
  border-radius: value($radius, normal);
  border: 1px solid var(--theme-disabled-color, $primaryDisabledColor);
  background-color: $whiteColor;
  padding: value($padding, normal);
  transition: all .2s;

  &.clickable {
    cursor: pointer;
  }

  &.small {
    padding: value($padding, small);
  }

  &.active {
    border-color: var(--theme-bg-color, $primaryBgColor);
    box-shadow: 0px 2px 12px 0px rgba(184, 184, 184, 0.5);
  }

  &.disabled {
    filter: grayscale(100%);
    opacity: .5;
    cursor: not-allowed;
  }
}