@import 'scss/variables.scss';

.scroll {
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 0;
    display: none;
  }
}

.resource {
  cursor: pointer;
  
  &.active {
    .photo {
      border-color: var(--theme-primary-color, $primaryColor);

      .inner {
        .check {
          display: flex;
        }
      }
    }
  }

  &.disabled {
    filter: grayscale(100%);
    opacity: .5;
    cursor: not-allowed;
  }

  .photo {
    width: 92px;
    min-width: 92px;
    height: 92px;
    border-radius: value($radius, small);
    border: solid 1px var(--theme-disabled-color, $primaryDisabledColor);
    padding: value($padding, xs);
    background-color: $whiteColor;

    .inner {
      width: 100%;
      height: 100%;
      border-radius: value($radius, xs);
      overflow: hidden;
      background-color: var(--theme-bg-color, $primaryBgColor);
      position: relative;
      z-index: 0;

      img {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 0;
        object-fit: cover;
        object-position: center;
      }

      .check {
        display: none;
        position: absolute;
        z-index: 1;
        inset: 0;

        &:after {
          content: '';
          display: block;
          position: absolute;
          z-index: -1;
          inset: 0;
          background-color: var(--theme-primary-color, $primaryBgColor);
          opacity: .5;
        }
      }
    }
  }
}