@import 'scss/variables.scss';

.loader {
  width: 16px;
  aspect-ratio: 1;
  --_c:no-repeat radial-gradient(farthest-side, var(--theme-primary-color, value($font, color, primary)) 92%, #0000);
  background: 
    var(--_c) top,
    var(--_c) left,
    var(--_c) right,
    var(--_c) bottom;
  background-size: 4px 4px;
  animation: l7 0.5s infinite;

  &.primary {
    --_c:no-repeat radial-gradient(farthest-side, #fff 92%, #0000);
    background: 
      var(--_c) top,
      var(--_c) left,
      var(--_c) right,
      var(--_c) bottom;
    background-size: 4px 4px;
  }

  &.highlight {
    --_c:no-repeat radial-gradient(farthest-side, var(--theme-primary-color, value($font, color, primary)) 92%, #0000);
    background: 
      var(--_c) top,
      var(--_c) left,
      var(--_c) right,
      var(--_c) bottom;
    background-size: 4px 4px;
  }

  &.xl {
    width: 64px;
    --_c:no-repeat radial-gradient(farthest-side, var(--theme-primary-color, value($font, color, primary)) 92%, #0000);
    background: 
      var(--_c) top,
      var(--_c) left,
      var(--_c) right,
      var(--_c) bottom;
    background-size: 16px 16px;
  }
}

@keyframes l7 {to{transform: rotate(.5turn)}}