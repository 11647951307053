@import 'scss/variables.scss';

.container {
  height: 100%;

  .content {
    position: relative;
    z-index: 0;
    overflow-y: auto;
    flex: 1;
    pointer-events: all;

    &::-webkit-scrollbar {
      width: 0;
      display: none;
    }

    .scroll {
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      right: 0;

      .top {
        height: $heroHeight;
        width: 100%;

        &:after {
          content: '';
          display: block;
          height: 32px;
          border-radius: value($radius, large) value($radius, large) 0 0;
          background-color: $whiteColor;
        }
      }

      .scrollContent {
        background-color: $whiteColor;
        padding: 0 value($padding, large);
        position: relative;
        z-index: 1;

        .logout {
          position: absolute;
          z-index: 1;
          top: 8px;
          inset-inline-end: 23px;
        }

        .topDiv {
          display: block;
          height: 32px;
          border-radius: value($radius, large) value($radius, large) 0 0;
          background-color: $whiteColor;
          position: absolute;
          z-index: -1;
          top: -32px;
          left: 0;
          right: 0;
        }

        .nameBox {
          overflow: hidden;
          height: 0;
          transition: all .2s;

          &.open {
            height: 95px;
          }

          .nameBoxInner {
            padding-top: value($padding, normal);
          }
        }

        .codeBox {
          overflow: hidden;
          height: 0;
          transition: all .2s;

          &.open {
            height: 64px;
          }

          .codeBoxInner {
            padding-top: value($padding, normal);
          }
        }
      }
    }
  }

  .button {
    position: relative;
    z-index: 2;
    box-shadow: 0 0 20px rgba(184, 184, 184, 0.5);

    &.bottom {
      box-shadow: none;
    }
  }
}