$mobileBreakpoint: 768px;
$heroHeight: 350px;

$step: 4px;

$primaryColor: #275A7B;
$primaryHoverColor: #1E76AE;
$primaryActiveColor: #1489D4;
$primaryDisabledColor: #A7BCCA;
$primaryLightColor: #EBF3F8;
$primaryBgColor: #D6E5EF;

$whiteColor: #fff;
$blackColor: #000;
$mutedColor: #8889a8;

$dangerColor: #FF4747;
$dangerHoverColor: #f16868;
$dangerActiveColor: #ff7b7b;
$dangerDisabledColor: #ffa0a0;
$dangerBgColor: #ffcccc;

$font: (
  family: 'Manrope',
  height: 1.4,

  size: (
    xs: 12px,
    small: 14px,
    normal: 16px,
    large: 20px,
    xl: 24px,
  ),

  weight: (
    light: 300,
    normal: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    black: 800,
  ),

  color: (
    primary: $blackColor,
    highlight: $primaryColor,
    muted: #7C8B9F,
    gray: #434343,
    danger: $dangerColor,
  ),
);

$gap: (
  xs: $step,
  small: $step * 2,
  normal: $step * 4,
  large: $step * 6,
  xl: $step * 8,
);

$padding: (
  xs: $step,
  small: $step * 2,
  normal: $step * 4,
  large: $step * 6,
  xl: $step * 8,
);

$radius: (
  xs: $step,
  small: $step * 2,
  normal: $step * 3,
  large: $step * 6,
  xl: $step * 8,
);

$button: (
  minWidth: 100px,

  primary: (
    color: $primaryColor,
    hover: $primaryHoverColor,
    active: $primaryActiveColor,
    disabled: $primaryDisabledColor,
    text: $whiteColor,
  ),

  primaryOutlined: (
    color: $primaryColor,
    hover: $primaryHoverColor,
    active: $primaryActiveColor,
    disabled: $primaryDisabledColor,
    text: $primaryColor,
  ),

  danger: (
    color: $dangerColor,
    hover: $dangerHoverColor,
    active: $dangerActiveColor,
    disabled: $dangerDisabledColor,
    text: $whiteColor,
  ),

  dangerOutlined: (
    color: $dangerColor,
    hover: $dangerHoverColor,
    active: $dangerActiveColor,
    disabled: $dangerDisabledColor,
    text: $dangerColor,
  ),
);

$input: (
  color: $blackColor,
  disabled: lighten($mutedColor, 38%),
  border: #E2E8F0,
  placeholder: #A0AEC0,
);

$card: (
  color: $whiteColor,
  shadow: (0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)),
);

// $dropdown: (
//   color: $whiteColor,
//   hover: (
//     primary: lighten($primaryLightColor, 78%),
//     secondary: lighten($secondaryLightColor, 3%),
//   ),
//   shadow: (0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)),
// );

@function value($map, $key, $nestedKey: null) {
  @if $nestedKey {
    @return map-get(map-get($map, $key), $nestedKey);
  } @else {
    @return map-get($map, $key);
  }
}