@import 'scss/variables.scss';

.badge {
  border-radius: value($radius, normal);
  border: 1px solid var(--theme-disabled-color, $primaryDisabledColor);
  background: $whiteColor;
  padding: value($padding, small) value($padding, normal);
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  transition: all .2s;
  cursor: pointer;

  &.active {
    border-color: var(--theme-primary-color, $primaryColor);
    background-color: var(--theme-bg-color, $primaryBgColor);
  }

  &.disabled {
    opacity: .5;
    cursor: not-allowed;
  }
}