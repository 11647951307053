@import 'scss/variables.scss';

.description {
  position: relative;
  z-index: 0;
  height: 15px;

  .inner {
    position: absolute;
    z-index: 0;
    inset: 0;

    &.hasTooltip {
      border-bottom: dashed $primaryDisabledColor 1px;

      &:hover {
        border-color: transparent;
      }
    }
  }
}

.hidden {
  position: absolute;
  visibility: hidden;
  white-space: nowrap;
}