@import 'scss/variables.scss';

.text {
  font-family: value($font, family);
  margin: 0;
  display: flex;
  align-items: center;
  gap: value($gap, xs);
  text-decoration: none;
}

.primary {
  color: value($font, color, primary);
}

.highlight {
  color: var(--theme-primary-color, value($font, color, highlight));
}

.muted {
  color: var(--theme-color-muted, value($font, color, muted));
}

.gray {
  color: value($font, color, gray);
}

.danger {
  color: value($font, color, danger);
}

.white {
  color: $whiteColor;
}

.italic {
  font-style: italic;
}

.nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.lines {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.normal {
  text-transform: none;
}

.center {
  text-align: center;
}

.left {
  text-align: start;
}

.right {
  text-align: end;
}

.justify {
  text-align: justify;
}

.sNormal {
  font-size: value($font, size, normal);
}

.sLarge {
  font-size: value($font, size, large);
}

.sSmall {
  font-size: value($font, size, small);
}

.sXl {
  font-size: value($font, size, xl);
}

.sXs {
  font-size: value($font, size, xs);
}

.wNormal {
  font-weight: value($font, weight, normal);
}

.wBold {
  font-weight: value($font, weight, bold);
}

.wLight {
  font-weight: value($font, weight, light);
}

.wMedium {
  font-weight: value($font, weight, medium);
}

.wSemiBold {
  font-weight: value($font, weight, semiBold);
}

.wBlack {
  font-weight: value($font, weight, black);
}

.hNormal {
  line-height: value($font, height);
}

@for $i from 0 through 64 {
  .s#{$i} {
    font-size: #{$i}px;
  }
}

@for $i from 0 through 900 {
  $value: $i * 100;
  .w#{$value} {
    font-weight: #{$value};
  }
}